// Object containing the original terms and their replacements
export const replacementsKeywords: { [key: string]: string } = {
    "mom": "step mom",
    "mother": "step mother",
    "mothers": "step mother",
    "dad": "step dad",
    "sister": "step sister",
    "brother": "step brother",
    "femboy": "man",
    "femboys": "men",
    "girl": "woman",
    "girs": "women",
    "boy": "man",
    "very": "",
    // Add more replacements as needed
  };